.ui.active.dimmer.cookie-content {
  justify-content: end;
}

.dimmed.dimmable > .ui.page.active.dimmer.cookie-content {
  background-color: rgb(from var(--primaryColor) r g b / 75%);
}

.ui.active.dimmer.cookie-content > .content {
  color: var(--primaryColor);
  background-color: #fff;
  padding: 24px 18px;
  border-radius: 10px;
  text-align: left;
  max-width: 820px;
  margin: 0 auto 24px;
  max-height: 100%;
  overflow-y: auto;
}

.ui.active.dimmer.cookie-content > .content h3 {
  font-size: 1.7rem;
  margin-bottom: 24px;
}

.ui.active.dimmer.cookie-content > .content p {
  margin-bottom: 12px;
}

.dimmed.dimmable .ui.grid > .column.cookie-banner-buttons {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.cookie-content .ui.button.btn-oultine,
.cookie-content .ui.button.btn-oultine:active,
.cookie-content .ui.button.btn-oultine:focus {
  background-color: transparent;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}

.cookie-content .ui.button.btn-oultine:hover {
  background-color: rgb(from var(--primaryColor) r g b / 25%);
  color: var(--primaryColor);
}

.ui.active.dimmer.cookie-content .accordion .title {
  color: var(--primaryColor);
  display: flex;
  align-items: center;
}

.ui.active.dimmer.cookie-content .accordion .title h4 {
  font-size: 18px;
  margin: 0;
}

.ui.active.dimmer.cookie-content .active.title i.icon {
  transform: rotate(90deg);
}

.ui.active.dimmer.cookie-content .divider.divider-cookie {
  border-top: 0;
  border-bottom: 1px solid rgb(from var(--primaryColor) r g b / 25%);
}

.ui.active.dimmer.cookie-content .ui.toggle.checkbox label:after {
  top: 0.15rem;
  width: 1.2rem;
  height: 1.2rem;
}

@media only screen and (min-width: 768px) {
  .dimmed.dimmable .ui.grid > .column.cookie-banner-buttons {
    flex-direction: row-reverse;
  }
}
